import React, { Component } from 'react'

export default class TermsOfUse extends Component {
  render() {
    return (
      <div>
       <h1> Политика конфиденциальности</h1>
<h2>I. Общие положения</h2>
Настоящее Положение о политике конфиденциальности (далее — Положение) является официальным документом ООО «Е-няня», расположенного по адресу: г. Москва, Чистопрудный б-р, д. 5, офис 117 (далее — «Компания»/ «Оператор»), и определяет порядок обработки и защиты информации о физических лицах (далее — Пользователи), пользующихся сервисами, информацией, услугами Компании, а также сайтом, расположенном на доменном имени office.e-nanny.ru  (далее — Сайт).
Соблюдение конфиденциальности важно для Компании, ведь целью данной Политики конфиденциальности является обеспечение защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну, от несанкционированного доступа и разглашения.
Мы разработали Политику Конфиденциальности, которая описывает, как мы осуществляем обработку персональных данных — любые действия (операции) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
Отношения, связанные с обработкой персональных данных регулируются настоящим Положением, иными официальными документами Оператора и действующим законодательством РФ
Обработка персональных данных осуществляется нами на законной и справедливой основе, действуя разумно и добросовестно и на основе принципов:
— законности целей и способов обработки персональных данных;
— добросовестности;
— соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Компании;
— соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных
Настоящая Политика Конфиденциальности регулирует любой вид обработки персональных данных и информации личного характера (любой информации, позволяющей установить личность, и любой иной информации, связанной с этим) о физических лицах, которые являются потребителями услуг Компании.
Настоящая Политика распространяется на обработку личных, персональных данных, собранных любыми средствами, как активными, так и пассивными, как через Интернет, так и без его использования, от лиц, находящихся в любой точке мира.


<h2>II. Сбор персональных данных</h2>
Целью обработки персональных данных является выполнения обязательств Оператора перед Пользователями в отношении использования Сайта и услуг Компании.
Обработка персональных данных пользователей осуществляется с согласия субъекта персональных данных на обработку его персональных данных.
Под персональными данными понимается любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных) и которая может быть использована для идентификации определенного лица либо связи с ним.
Мы можем запросить у Вас персональные данные в любой момент, когда Вы связываетесь с Компанией. Компания может использовать такие данные в соответствии с настоящей Политикой Конфиденциальности. Она также может совмещать такую информацию с иной информацией для целей предоставления и улучшения своих услуг и коммуникаций с субъектом персональных данных.
Ниже приведены некоторые примеры типов персональных данных, которые Компания может собирать, и как мы можем использовать такую информацию.


КАКИЕ ПЕРСОНАЛЬНЫЕ ДАННЫЕ МЫ СОБИРАЕМ
Мы можем собирать различные данные/информацию, включая:
— имя, отчество и фамилию
— пол, возраст;
—  дата и место рождения;
— паспортные данные;
— фотография;
— адрес регистрации по месту жительства и адрес фактического проживания;
— номер телефона;
— адрес электронной почты;
— данные документов об образовании, квалификации, профессиональной подготовке, сведения о повышении квалификации;
— данные из трудовой книжки;
—  данные о работе в семьях;
— сведения о деловых и иных личных качествах, носящих оценочный характер.
Персональные данные могут также включать в себя дополнительно предоставляемые Пользователями по запросу Оператора в целях исполнения Оператором обязательств перед Пользователями, вытекающих из договора на оказание услуг.
Когда Вы приглашаете вопользоваться нашими услугами других лиц, Компания может собирать предоставляемые Вами персональные данные об этих лицах, такую как: имя, фамилия, адрес электронной почты и номер телефона.
При обработке персональных данных нами обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных.


<h2>III. Хранение и использование персональных данных</h2>
Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.
КАК МЫ ИСПОЛЬЗУЕМ ВАШУ ПЕРСОНАЛЬНУЮ ИНФОРМАЦИЮ
Собираемые нами персональные данные позволяют осуществлять коммуникацию с Вами в целях подбора работы для Вас, а также позволяют подобрать вакансию, наиболее точно соответствующую Вашим требованиям.


<h2>IV. Передача персональных данных</h2>
Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящими Правилами.
Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств
Пользователь соглашается с тем, что Оператор вправе передавать персональные данные работодателям., исключительно для целей, указанных в разделе «Сбор персональных данных» настоящей Политики конфиденциальности
Персональные данные Пользователя могут быть переданы по запросам уполномоченных органов государственной власти РФ только по основаниям и в порядке, установленным законодательством РФ.
Оператор осуществляет блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.


ИНЫЕ ЛИЦА
Компании может быть необходимо — в соответствии с законом, судебным порядком, в судебном разбирательстве и/или на основании публичных запросов или запросов от государственных органов на территории или вне территории страны Вашего пребывания — раскрыть Ваши персональные данные. Мы также можем раскрывать персональные данные/информацию о Вас, если мы определим, что такое раскрытие необходимо или уместно в целях национальной безопасности, поддержания правопорядка или иных общественно важных случаях.
Мы также можем раскрывать персональные данные/информацию о Вас, если мы определим, что раскрытие необходимо для приведения в исполнение наших положений и условий либо для целей защиты нашей деятельности и наших пользователей. Дополнительно в случае реорганизации, слияния или продажи мы можем передать любую или всю собираемую нами персональную информацию соответствующему третьему лицу.


<h2>V. Уничтожение персональных данных</h2>
Персональные данные пользователя уничтожаются при отзыве субъектом персональных данных согласия на обработку персональных данных.


<h2>VI. Защита персональных данных</h2>
Компания предпринимает меры предосторожности — включая правовые, организационные, административные, технические и физические — для обеспечения защиты Ваших персональных данных в соответствии со ст. 19 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» в целях обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
ЦЕЛОСТНОСТЬ И СОХРАНЕНИЕ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
Мы будем хранить Ваши персональные данные и информацию в течение срока, необходимого для выполнения целей, описываемых в настоящей Политике Конфиденциальности, за исключением случаев, когда более длительный период хранения данных и информации необходим в соответствии с законодательством либо разрешён им.
Мы не собираем персональные данные о несовершеннолетних. Если нам станет известно о том, что мы получили персональные данные о несовершеннолетнем, мы предпримем меры для удаления такой информации в максимально короткий срок.
Мы настоятельно рекомендуем родителям и иным лицам, под чьим присмотром находятся несовершеннолетние (законные представители — родители, усыновители или попечители), контролировать использование несовершеннолетними веб-сайтов.
СОБЛЮДЕНИЕ ВАШЕЙ КОНФИДЕНЦИАЛЬНОСТИ НА УРОВНЕ КОМПАНИИ
Для того чтобы убедиться, что Ваши персональные данные находятся в безопасности, мы доводим нормы соблюдения конфиденциальности и безопасности до работников Компании и строго следим за исполнением мер соблюдения конфиденциальности внутри Компании.
ВОПРОСЫ ОТНОСИТЕЛЬНО КОНФИДЕНЦИАЛЬНОСТИ
Если у вас возникнут вопросы в отношении Политики Конфиденциальности Компании или обработки данных Компанией, Вы можете связаться с нами по контактам, указанным на Сайте.


<h2>VII. Обращения пользователей</h2>
К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором применяется действующее законодательство РФ.
Пользователи вправе направлять Оператору свои запросы, в том числе запросы относительно использования их персональных данных, направления отзыва согласия на обработку персональных данных в письменной форме по адресу e-nanny@mail.ru Запрос, направляемый Пользователем, должен соответствовать требованиям, установленным Правилами подачи обращений в Службу сервиса и поддержки, а именно содержать:
— подпись Пользователя или его представителя;
— адрес электронной почты;
— контактный телефон.
Оператор обязуется рассмотреть и направить ответ на поступивший запрос Пользователя в течение 30 дней с момента поступления обращения.
ДРУГОЕ
Во всем остальном, что не отражено напрямую в Политике Конфиденциальности, Компания обязуется руководствоваться нормами и положениями Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных»
Посетитель сайта Компании, предоставляющий свои персональные данные и информацию, тем самым соглашается с положениями данной Политики Конфиденциальности.
Компания оставляет за собой право вносить любые изменения в Политику в любое время по своему усмотрению с целью дальнейшего совершенствования системы защиты от несанкционированного доступа к сообщаемым Пользователями персональным данным без согласия Пользователя. Когда мы вносим существенные изменения в Политику Конфиденциальности, на нашем сайте размещается соответствующее уведомление вместе с обновлённой версией Политики Конфиденциальности.


      </div>
    )
  }
}
