import React, { Component } from 'react'
import '../../Source/Styles/Anketa.css'
import { AddUserViewModel } from '../../ViewModels/AddUserViewModel';
import {api} from '../../http/http';
import axios from 'axios';

export  class Anketa extends Component {
    constructor(props) {
        super()
        this.state={
          FilterResult:null,
          loading:true,
          currentUser:  new AddUserViewModel(),
          UploadedFile:null
        }
        
      }

      async componentDidMount() {
        var filters = await api.get("Filters")
        this.setState({FilterResult :filters})
        this.setState({loading : false})
      }
      
  renderChoice(result){
    return(
        <div className='Anketa__Filter__Result'>
        <div>
            <h6>Должность</h6>
              <select onChange={e=>this.state.currentUser.staffTypeId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
                
                {
                  result.staffType.map(val=><option  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
             </select>
        </div>
        <div>
            <h6>Направление</h6>
              <select onChange={e=>this.state.currentUser.directionId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
                <option data-id={null}>Нет</option>
                {
                  result.direction.map(val=><option  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
             </select>
        </div>
        <div>
            <h6>Город</h6>
              <select onChange={e=>this.state.currentUser.cityId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
                {
                  result.city.map(val=><option  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
             </select>
        </div>
        <div>
            <h6>Метро</h6>
              <select onChange={e=>this.state.currentUser.metroStationId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
              <option  data-id={null}>Нет</option>
                {
                  result.metro.map(val=><option  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
             </select>
        </div>
        <div>
            <h6>Пол</h6>
              <select onChange={e=>this.state.currentUser.genderId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
              <option  data-id={2}>Женский</option>
              <option  data-id={1}>Мужской</option>
             
             </select>
        </div>
       
        
       
        </div>
    )
  }
  duties(result){
    return(
    <div>
            <div className='Filter__Column'style={{backgroundColor:"#DEF7FE",height:"85vh"}}>
           <h2 className='Filter__Title'>Обязанности</h2>
           <div style={{display:"flex",flexDirection:"column",width:"45vw",flexWrap:"wrap",height:"83vh"}}>
            
           {
            result.duty.map(dut => <div key={"dut-"+dut.id} className='Filter__Row' style={{width:"fit-content"}}> 
            <input onChange={()=>this.changeViewModelArrayValue(this.state.currentUser.Staff2DutyTypes,dut.id)} id={"dut_"+dut.id} value={dut.id} type="checkbox"/>
            <label htmlFor={"dut_"+dut.id}>{dut.name}</label>
            </div>)
          }
          <div className='Filter__Row' style={{backgroundColor:"#C3FBD8"}}>
          <div className='Filter__Column'>
           <h2 className='Filter__Title'>График</h2>
           <div>
           {
            result.scheduleType.map(st => <div key={"st-"+st.id} className='Filter__Row' > 
            <input onChange={()=>this.changeViewModelArrayValue(this.state.currentUser.Staff2ScheduleTypes,st.id)} id={"st_"+st.id} value={st.id} type="checkbox"/>
            <label htmlFor={"st_"+st.id}>{st.name}</label>
            </div>)
          }
           </div>
          </div>
           
         </div>
          </div>
          
         </div>
    </div>
    )
  }
  async send(){
   this.state.currentUser.finalize()
   var staff = this.state.currentUser;
   return await api.post("AddNewStaff",staff)
   
  }
  async submit(){
    var bodyFormData = new FormData();
    bodyFormData.append('uploadedFile', this.state.UploadedFile); 
    var staffResult = await this.send()
    
    
    bodyFormData.append("Id",staffResult.id)
    await  api.submitForm('UploadFile',bodyFormData)
    this.setState('UploadedFile',null)
    
   
   
   
   
   


      
  }
  changeViewModelArrayValue(obj,value){
    let currentIndex = obj.indexOf(value);
    if (currentIndex !== -1) {
      obj.splice(currentIndex, 1);
    }
    else{
      obj.push(value)
    }  
  }
  chitizenChoice(result){
    return(
<div>
<div>
            <h6>Гражданство</h6>
              <select onChange={e=>this.state.currentUser.citizenId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
                {
                  result.citizenship.map(val=><option  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
             </select>
        </div>
        <div>
        <h6>Национальность</h6>
              <select onChange={e=>this.state.currentUser.citizenId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
                {
                  result.nationality.map(val=><option  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
             </select>
        </div>
</div>
    )
  } 
  render() {
    let choice = this.state.loading?<div>Загрузка...</div>:this.renderChoice(this.state.FilterResult)
    let duty = this.state.loading?<div>Загрзка...</div>: this.duties(this.state.FilterResult)
    let citizen = this.state.loading?<div>Загрзка...</div>: this.chitizenChoice(this.state.FilterResult)
    return (
      <div className='Anketa__Page'>
        <input onChange={e=>this.state.currentUser.fcs=e.target.value} type='text' className='Text_Input' placeholder='ФИО' />
        <input onChange={e=>this.state.currentUser.email=e.target.value} type='text' className='Text_Input' placeholder='Электронная почта' />
        <input onChange={e=>this.state.currentUser.skype=e.target.value} type='text' className='Text_Input' placeholder='Skype' />
        <input onChange={e=>this.state.currentUser.height=e.target.value} type='number' className='Text_Input' placeholder='Рост' />
        <input onChange={e=>this.state.currentUser.width=e.target.value} type='number' className='Text_Input' placeholder='Вес' />
        <input onChange={e=>this.state.currentUser.clothSize=e.target.value} type='text' className='Text_Input' placeholder='Размер одежды' />
        <input onChange={e=>this.state.currentUser.distMetro=e.target.value} type="text" className='Text_Input' placeholder='Дистанция до метро'/>
        <input onChange={e=>this.state.currentUser.educatuinCurses=e.target.value} type='text' className='Text_Input' placeholder='Образование,курсы' />
        <input onChange={e=>this.state.currentUser.moreVisa=e.target.value} type='text' className='Text_Input' placeholder='Визы'/>
        <input onChange={e=>this.state.currentUser.moreLang=e.target.value} type='text' className='Text_Input' placeholder='Языки' />
        <input onChange={e=>this.state.currentUser.moreTravel=e.target.value} type='text' className='Text_Input' placeholder='Поездки'/>
        <input onChange={e=>this.state.currentUser.moreAllergy=e.target.value} type='text' className='Text_Input' placeholder='Аллергия' />
        <input onChange={e=>this.state.currentUser.technique=e.target.value} type='text' className='Text_Input' placeholder='Техники' />
        <input onChange={e=>this.state.currentUser.skills=e.target.value} type='text' className='Text_Input' placeholder='Навыки, о себе' />
        <input onChange={e=>this.state.currentUser.hobbies=e.target.value} type='text' className='Text_Input' placeholder='Хобби' />
        <input onChange={e=>this.state.currentUser.personality=e.target.value} type='text' className='Text_Input' placeholder='Лич. кач.' />
        <input onChange={e=>this.state.currentUser.salaryPerMoth=e.target.value} type='number' className='Text_Input' placeholder='Зарплата в месяц'/>
        <input onChange={e=>this.state.currentUser.salaryPerDay=e.target.value} type='number' className='Text_Input' placeholder='Зарплата в день'/>
        <input onChange={e=>this.state.currentUser.salaryPerHour=e.target.value} type='number' className='Text_Input' placeholder='Зарплата в час'/>
        <input onChange={e=>this.state.currentUser.comissionPercent=e.target.value} type='number' className='Text_Input' defaultValue={50} placeholder='% комиссии'/>
        <input onChange={e=>this.state.currentUser.moreConfession=e.target.value} type='text' className='Text_Input' placeholder='Религия' />
        <input onChange={e=>this.state.currentUser.commentFromOldDb=e.target.value} type='text' className='Text_Input' placeholder='Комментарий модератора' />
        <input onChange={e=>this.state.currentUser.childAgeFrom=e.target.value} type='number' className='Text_Input' placeholder='Дети от' />
        <input onChange={e=>this.state.currentUser.childAgeTo=e.target.value} type='number' className='Text_Input' placeholder='Дети до' />
       
        <div className='Labeled_Checkbox'>
            <input onChange={()=>this.state.currentUser.isSmoker = !this.state.currentUser.isSmoker} type='checkbox'/>
            <label>Курит</label>
        </div>
        <div className='Labeled_Checkbox'>
            <input onChange={()=>this.state.currentUser.hasCovidDocumet = !this.state.currentUser.hasCovidDocumet } type='checkbox'/>
            <label>Есть сертификат covid19</label>
        </div>
        <div className='Labeled_Checkbox'>
            <input  onChange={()=>this.state.currentUser.fearOfDogs = !this.state.currentUser.fearOfDogs} type='checkbox'/>
            <label>Боится собак</label>
        </div>
        <div className='Labeled_Checkbox'>
            <input  onChange={()=>this.state.currentUser.workedFromUs = !this.state.currentUser.workedFromUs} type='checkbox'/>
            <label>Работал(а) с нами</label>
        </div>
        <div className='Labeled_Checkbox'>
            <input  onChange={()=>this.state.currentUser.hasPatent =! this.state.currentUser.hasPatent}  type='checkbox'/>
            <label>Есть патент</label>
        </div>
        <div className='Labeled_Checkbox'>
            <input  onChange={()=>this.state.currentUser.hasMedknigka = !this.state.currentUser.hasMedknigka} type='checkbox'/>
            <label>Есть мед-книжка</label>
        </div>
        <div className='Labeled_Checkbox'>
            <input  onChange={()=>this.state.currentUser.hasSudimost = !this.state.currentUser.hasSudimost} type='checkbox'/>
            <label>Есть судимость</label>
        </div>
        <div className='Labeled_Checkbox'>
            <input  onChange={()=>this.state.currentUser.hasBiopassport=!this.state.currentUser.hasBiopassport} type='checkbox'/>
            <label>Есть био-паспорт</label>
        </div>
        <div className='Labeled_Checkbox'>
            <input  onChange={()=>this.state.currentUser.passporInOffice = !this.state.currentUser.passporInOffice} type='checkbox'/>
            <label>Паспорт подтверждён</label>
        </div>
        <div className='Labeled_Checkbox'>
            <input  onChange={()=>this.state.currentUser.hasRvp = !this.state.currentUser.hasRvp} type='checkbox'/>
            <label>Есть рвп</label>
        </div>
        <div className='Labeled_Input'>
        <label>День рождения</label>
        <input onChange={e => this.state.currentUser.birthday= e.target.value} type="date" className='Text_Input'/>
        </div>
        {choice}
        {duty}
        {citizen}






        


        
        <div className='Labeled_Checkbox'>
            <input   onChange={()=>this.state.currentUser.acceptedForModeration =!this.state.currentUser.acceptedForModeration}  type='checkbox'/>
            <label>Модерация пройдена</label>
        </div>
    

       
        <div className='Labeled_Input'>
        <label >Фото</label>
        <input name='uploadedFile' onChange={e=>{this.setState({UploadedFile:e.target.files[0]})}} accept="image/*"   type='file' placeholder='Photo'/>
        </div>
      
        <button onClick={()=>this.submit()} style={{width:"12vw"}}>Добавить</button>

      </div>
    )
  }
}

