import React, { Component } from 'react'
import { Container } from 'reactstrap'
import '../../Source/Styles/Register.css'
import {api} from '../../http/http'

import { UserViewModel } from '../../ViewModels/UserViewModel';
import ExpirienctPage from '../ExpiriencePage';
import RegisterFinal from '../RegisterFinal'

export default class Register extends Component {
  constructor(props) {
    super()
    this.state={ 
      FilterResult:null,
      loading:true,
      currentPage:0,
      acceptTerms:false,
      currentUser:  new UserViewModel(),
      name:" ",
      sname:" ",
      mname:" ",
      citySelectIndex:0,
      metroSelectIndex:0,
      staffTypeIndex:0,
      currentPhoto:null,
      phone:"",
      result:"",
      error:""
      
    }
  }
  setPhoto(photo){
    this.setState({currentPhoto:photo})
  }
  normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    this.state.currentUser.idNavigation.Phones[0].Number = currentValue;
    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 5) return currentValue;
      if (cvLength < 8) return `${currentValue.slice(0,1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(4)}`;
      return `${currentValue.slice(0,1)}(${currentValue.slice(1, 4)}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 11)}`;
    }
  };
  async componentDidMount() {
    this.setState({currentUser:  new UserViewModel()})
    var filters = await fetch("api/Filters");
  
    this.setState({FilterResult :await filters.json()})
    this.setState({loading : false})
  }
  async Submit(){
    var staff = this.state.currentUser;
    var result = await api.post("Register", staff)
    if(result.id != undefined){
      var bodyFormData = new FormData();
      bodyFormData.append("Id",result.id)
      bodyFormData.append('uploadedFile', this.state.currentPhoto); 
      await  api.submitForm('UploadFile',bodyFormData)
      this.setState({ 
        FilterResult:this.state.FilterResult,
        loading:false,
        currentPage:0,
        acceptTerms:false,
        currentUser:  new UserViewModel(),
        name:" ",
        sname:" ",
        mname:" ",
        citySelectIndex:0,
        metroSelectIndex:0,
        staffTypeIndex:0,
        currentPhoto:null,
        phone:"",
        result:"",
        error:""
        
      })
      alert("Анкета отправлена на проверку!")
    }
    else{
      this.setState({error:"Ошибка отправки!"})
    }
    
  }
  inputTelephoneNumber({target:{value}}){
    this.setState(prevState=> ({ phone: this.normalizeInput(value, prevState.phone) }));

  }
  isNotEmpty(str){
  return str != null && str!="" & str !=" " && str!= undefined && str!=NaN
  }
  changePageState(value ){    
    if (value == 10){
      var currentUser = this.state.currentUser;
      var phoneInValid = currentUser.idNavigation.Phones[0].Number.length < 11;
      var childAgeInValid = (currentUser.staffTypeId==1 || currentUser.staffTypeId==2) 
                            && (currentUser.childAgeFrom == null || currentUser.childAgeTo == null);
      var salaryInValid = currentUser.salaryPerMoth == null && currentUser.salaryPerDay == null && currentUser.salaryPerHour == null
      var dutyInValid = currentUser.Staff2DutyTypes.length <1
      var scheduleTypeInValid = currentUser.Staff2ScheduleTypes.length<1
      var currentError = "";
      if(phoneInValid){
        currentError+="Номер телефона|"
      }
      if(childAgeInValid){
        currentError+="Возраст детей|"
      }
      if(salaryInValid){
        currentError+="Зарплатные ожидания|"
      }
      if(dutyInValid){
        currentError+="Обязанности|"
      }
      if(scheduleTypeInValid){
        currentError+="График|"
      }
      if(currentError.length>0){
        var errorResult = "Не все обязательные поля заполнены:" + currentError
        this.setState({error:errorResult})
        return;
      }
      else{
        this.setState({error:""})
      }
    }
    this.setState({currentPage:value})
   
  }
  changeViewModelArrayValue(obj,value){
    let currentIndex = obj.indexOf(value);
    if (currentIndex !== -1) {
      obj.splice(currentIndex, 1);
    }
    else{
      obj.push(value)
    }  
  }
  setExpirience(value){
    this.state.currentUser.staffExperiences = value
  }
  renderStates(result){
    switch(this.state.currentPage) {
      case 0 :
        return this.renderFirst(result)
      case 1:
        return this.renderChoise(result)
      case 2:
        return this.renderSecond(result)
      case 3:
        return this.renderThird(result)
      case 4:
        return this.renderFourth(result)
      case 5:
        return this.renderFifth(result)
      case 6:
        return this.renderSixth(result)
      case 7:
        return this.renderSeventh(result)
      case 8:{
        return this.renderLast(result)
      }
      case 9:{
        return this.renderEighth(result)
      }
      case 10:
        return this.renderResponse()
       default:
        return <div></div>
      
      
    }
  }
  renderChoise(result){
    return(<div className='Registration__Page__Categories' >
      <h1>Заполните анкету</h1>
      <div className='Error'>{this.state.error}</div>
            <div className='Register__Page__Catrgory' onClick={() => this.changePageState(2)}>
            <div>
            Контактные данные
            </div>
           
            </div>
            <div className='Register__Page__Catrgory' onClick={() => this.changePageState(3)}>
              <div>
              Параметры 
              </div>
           
            </div>
            <div className='Register__Page__Catrgory' onClick={() => this.changePageState(5)}>
              <div>
              О себе
              </div>
            
            </div>
          { this.state.staffTypeIndex==0 || this.state.staffTypeIndex==1?
            <div  className={'Register__Page__Catrgory'} onClick={() => this.changePageState(6)}>
              <div >
              Работа с детьми
                </div>
           
            </div>
            :
            null
            }
            <div className='Register__Page__Catrgory' onClick={() => this.changePageState(7)}>
              <div>
              Обязанности
              </div>
            
            </div>
            <div className='Register__Page__Catrgory' onClick={() => this.changePageState(8)}>
              <div>
              График и зарплата
              </div>
            
            </div>
            <div className='Register__Page__Catrgory' onClick={() => this.changePageState(9)}>
            <div>
              Опыт работы в семьях
            </div>
            </div>
            <button onClick={() => this.changePageState(10)} >Далее</button>
      
            
          </div>)
  }
  renderFirst(result){
    return (
      <div className='RegisterPage'>
        <h2 style={{fontSize:"1.4rem"}}>Зарегистрируйтесь, чтобы получать вакансии</h2>
        <label className='Register__Required'>Фамилия</label>
        <input  onChange={(e) => {this.state.currentUser.sname = e.target.value;this.setState({sname:e.target.value})}} type='text'/>
        <label className='Register__Required'>Имя</label>
        <input onChange={(e) => {this.state.currentUser.name = e.target.value;this.setState({name:e.target.value})}} type='text'/>
        <label>Отчество</label>
        <input onChange={(e) => {this.state.currentUser.mname = e.target.value;this.setState({mname:e.target.value})}} placeholder='Нет' type='text'/>
        <label className='Register__Required'>Дата рождения</label>
        <input onChange={(e) => {this.state.currentUser.birthday = e.target.value}} type='date'/>
        <label>Пол</label>
        <select onChange={e=>this.state.currentUser.genderId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
              <option  data-id={2}>Женский</option>
              <option  data-id={1}>Мужской</option>
             
             </select>
        <label>Регион постоянной прописки</label>
            <select onChange={e=>this.state.currentUser.citizenId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
              {
                result.citizenship.map(val=><option  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
              }
             </select>
        <label>Национальность</label>
        <select onChange={e=>this.state.currentUser.citizenId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
                {
                  result.nationality.map(val=><option  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
          </select>
         
      </div>
    )
  }
  renderSecond(result){
    return(
    <div className='RegisterPage'>
      <h2>Контактные данные</h2>
      <label> Email</label>
      <input onChange={e=>this.state.currentUser.idNavigation.EmailAddresses[0].Email = e.target.value} placeholder='Нет' type="email" value={this.state.currentUser.email}/>
      <label  className='Register__Required'> Номер телефона</label>
      <input  onChange={this.inputTelephoneNumber.bind(this)} placeholder="x (xxx) xxx-xxxx" type="text" value={this.state.phone} />
     <div  className='RegisterPage__Row__Phone'>
     <label>К номеру привзяан WhatsApp?</label>
      <input  type="checkbox" onChange={()=>this.state.currentUser.idNavigation.Phones[0].isWhatsapp = !this.state.currentUser.idNavigation.Phones[0].isWhatsapp } />
     </div>
     <div  className='RegisterPage__Row__Phone'>
     <label>К номеру привзяан Telegram?</label>
      <input type="checkbox" onChange={()=>this.state.currentUser.idNavigation.Phones[0].isTelegram = !this.state.currentUser.idNavigation.Phones[0].isTelegram}/>
     </div>
      
    </div>)
  }
  renderThird(result){
return(
  <div className='RegisterPage'>
    <h2>Параметры</h2>
    <label>Рост</label>
    <input onChange={e => this.state.currentUser.height=e.target.value} type="number" placeholder='см' min="0" value={this.state.currentUser.height}/>
    <label>Вес</label>
    <input onChange={e => this.state.currentUser.width=e.target.value}  type="number" placeholder='кг' min="0"value={this.state.currentUser.width}/>
    <label>Размер одежды</label>
    <input onChange={e => this.state.currentUser.clothSize=e.target.value} type="text" value={this.state.currentUser.clothSize}/>
    <label>Визы</label>
    <input  onChange={e => this.state.currentUser.moreVisa=e.target.value} type="text" placeholder='Нет' value={this.state.currentUser.moreVisa}/>
    <label>Языки</label>
    <input  onChange={e => this.state.currentUser.moreLang=e.target.value} placeholder="через запятую"type="text"value={this.state.currentUser.moreLang}/>
    <label>Образование, курсы</label>
    <textarea  onChange={e => this.state.currentUser.educatuinCurses=e.target.value } value={this.state.currentUser.educatuinCurses}/>
    <label>Необходимые поездки(сколько раз в год, куда)</label>
    <input  onChange={e => this.state.currentUser.moreTravel=e.target.value} type="text" placeholder='Нет'value={this.state.currentUser.moreTravel}/>
    

  </div>
)
  }
  renderFourth(result){
    return(<div className='RegisterPage'>
      <h2>О себе</h2>
      <label>Хобби</label>
      <textarea onChange={e=>this.state.currentUser.hobbies = e.target.value} value={this.state.currentUser.hobbies}/>
      <label>Навыки</label>
      <textarea onChange={e=>this.state.currentUser.skills = e.target.value } value={this.state.currentUser.skills}/>
      <label>Личные качества</label>
      <input onChange={e=>this.state.currentUser.personality = e.target.value} type='text' value={this.state.currentUser.personality}/>
      <label>Религия</label>
      <input onChange={e=>this.state.currentUser.moreConfession = e.target.value} type='text'value={this.state.currentUser.moreConfession}/>
      
    </div>)
  }
  renderFifth(result){
return(
  <div className='RegisterPage'>
    <h2>О себе</h2>
    <label className='Register__Required'>Какую работу ищете?</label>
    <select  onChange={e=>{this.state.currentUser.staffTypeId=e.target[e.target.selectedIndex].getAttribute("data-id");
                       this.setState({staffTypeIndex:e.target.selectedIndex})}} >
                
                {
                  result.staffType.map((val,index)=><option selected={this.state.staffTypeIndex == index}  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
             </select>
             <div>
        </div>
        <div>
            <h6 className='Register__Required'>Город</h6>
              <select onChange={e=>{this.state.currentUser.cityId=e.target[e.target.selectedIndex].getAttribute("data-id");this.setState({citySelectIndex:e.target.selectedIndex})}}>
                {
                  result.city.map((val,index)=><option selected={this.state.citySelectIndex == index} key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
             </select>
        </div>
        <div>
            <h6>Ближайшее метро</h6>
              <select onChange={e=>{this.state.currentUser.metroStationId=e.target[e.target.selectedIndex].getAttribute("data-id");this.setState({metroSelectIndex:e.target.selectedIndex})}}>
              <option  data-id={null}>Нет</option>
                {
                  result.metro.map((val,index)=><option selected={this.state.metroSelectIndex == index}  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
                 }
             </select>
        </div>
        <label>Аллергия</label>
        <input onChange={e=>this.state.currentUser.moreAllergy = e.target.value} type="text" placeholder='Нет'value={this.state.currentUser.moreAllergy} />
        <div style={{justifyContent:"center",width:"30vw"}}>
        <div className='RegisterPage__Row'>
            <input onChange={()=>this.state.currentUser.isSmoker = !this.state.currentUser.isSmoker} type='checkbox' defaultChecked={this.state.currentUser.isSmoker}/>
            <label>Курите</label>
        </div>
        <div className='RegisterPage__Row'>
            <input  onChange={()=>this.state.currentUser.fearOfDogs = !this.state.currentUser.fearOfDogs} type='checkbox' defaultChecked={this.state.currentUser.fearOfDogs}/>
            <label>Боитесь собак</label>
        </div>
        <div className='RegisterPage__Row'>
            <input  onChange={()=>this.state.currentUser.hasSudimost = !this.state.currentUser.hasSudimost} type='checkbox' defaultChecked={this.state.currentUser.hasSudimost}/>
            <label>Есть судимость</label>
        </div>
        <div className='RegisterPage__Row'>
            <input onChange={()=>this.state.currentUser.hasCovidDocumet = !this.state.currentUser.hasCovidDocumet } type='checkbox' defaultChecked={this.state.currentUser.hasCovidDocumet}/>
            <label>Есть сертификат covid19</label>
        </div>
        <div className='RegisterPage__Row'>
            <input  onChange={()=>this.state.currentUser.hasPatent =! this.state.currentUser.hasPatent} defaultChecked={this.state.currentUser.hasPatent} type='checkbox'/>
            <label>Есть патент</label>
        </div>
        <div className='RegisterPage__Row'>
            <input  onChange={()=>this.state.currentUser.hasMedknigka = !this.state.currentUser.hasMedknigka} defaultChecked={this.state.currentUser.hasMedknigka} type='checkbox'/>
            <label>Есть мед-книжка</label>
        </div>
        <div className='RegisterPage__Row'>
            <input  onChange={()=>this.state.currentUser.hasBiopassport=!this.state.currentUser.hasBiopassport} defaultChecked={this.state.currentUser.hasBiopassport} type='checkbox'/>
            <label>Есть био-паспорт</label>
        </div>
        <div className='RegisterPage__Row'>
            <input  onChange={()=>this.state.currentUser.hasRvp = !this.state.currentUser.hasRvp} defaultChecked={this.state.currentUser.hasRvp} type='checkbox'/>
            <label>Есть рвп</label>
        </div>
        </div>
        <button onClick={()=>this.changePageState(4)}>Далее</button>
     
  </div>
)
  }
  renderSixth(result){
   
      return(
        <div className='RegisterPage'>
          <h2>Работа с детьми</h2>
        <label>
          Какие техники используете?
        </label>
        <input onChange={e=>this.state.currentUser.technique = e.target.value} value={this.state.currentUser.technique} placeholder='Никакие' type="text"/>
        <label className='Register__Required'>
          С какими детьми готовы работать?
        </label>
        <input onChange={e=>this.state.currentUser.childAgeFrom=e.target.value} value={this.state.currentUser.childAgeFrom} type='number' className='Text_Input' placeholder='Дети от' />
        <input onChange={e=>this.state.currentUser.childAgeTo=e.target.value} value={this.state.currentUser.childAgeTo} type='number' className='Text_Input' placeholder='Дети до' />
        </div>
      )
  }
  renderSeventh(result){
return(
  <div className='RegisterPage'>
    <h2 className='Register__Required'>Какие обязанности готовы выполнять?</h2>
    <div className='RegisterPage_Duties'>

  
    {
            result.duty.map(dut => <div key={"dut-"+dut.id} className='RegisterPage__Row' style={{width:"fit-content"}}> 
            <input style={{width:"4vh"}} onChange={()=>this.changeViewModelArrayValue(this.state.currentUser.Staff2DutyTypes,dut.id)} defaultChecked={this.state.currentUser.Staff2DutyTypes.find(q => q==dut.id)} id={"dut_"+dut.id} value={dut.id} type="checkbox"/>
            <label htmlFor={"dut_"+dut.id}>{dut.name}</label>
            </div>)
          }
    </div>
  </div>
)
  }
  renderEighth(result){
    return(
        this.state.currentPage==9?<ExpirienctPage staffExperiences={this.state.currentUser.staffExperiences}  filters={this.state.FilterResult} setExperiences={this.setExpirience.bind(this)}  changePageState={this.changePageState.bind(this)}/>:this.renderStates(result)
        )
  }
  renderLast(result){
    
    return(
      <div className='RegisterPage'>
         <h2 className='Register__Required'>Предпочитаемый график</h2>
    <div>
           {
            result.scheduleType.map(st => <div key={"st-"+st.id} className='Register__Row' > 
            <input onChange={()=>this.changeViewModelArrayValue(this.state.currentUser.Staff2ScheduleTypes,st.id)} id={"st_"+st.id} defaultChecked={this.state.currentUser.Staff2ScheduleTypes.find(q => q==st.id)} value={st.id} type="checkbox"/>
            <label htmlFor={"st_"+st.id}>{st.name}</label>
            </div>)
          }
           </div>
           <h2 className='Register__Required'>Зарплатные ожидания</h2>
           <h6>Заполните одно или все поля</h6>
          <input onChange={e=>this.state.currentUser.salaryPerMoth=e.target.value} type='number' value={this.state.currentUser.salaryPerMoth} className='Text_Input' placeholder='Зарплата в месяц'/>
        <input onChange={e=>this.state.currentUser.salaryPerDay=e.target.value} type='number'value={this.state.currentUser.salaryPerDay} className='Text_Input' placeholder='Зарплата в день'/>
        <input onChange={e=>this.state.currentUser.salaryPerHour=e.target.value} type='number' value={this.state.currentUser.salaryPerHour}className='Text_Input' placeholder='Зарплата в час'/>
      </div>
    )
  }
  renderLoaded(fragment){
    if(this.state.loading){
      return <div>Загрузка...</div>
    }
    return(fragment)
  }
  renderResponse(){
    return(
      <RegisterFinal Submit={this.Submit.bind(this)} result={this.state.result} currentUser={this.state.currentUser} setPhoto={this.setPhoto.bind(this)} renderLoaded = {this.renderLoaded.bind(this)}/>
    )
  }
  render() {
   
    let currentComponent = this.state.loading?<div>Загрузка...</div>:this.renderStates(this.state.FilterResult);
    
    return (
      <div className='Registration__Page_'>
     <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      
      <Container className='RegisterPage__Container'>
        <div className='RegisterPage'>
      
        {
          this.state.currentPage == 10?<div className='Registration__Page__BackArrow' onClick={() => {this.changePageState(1);this.renderChoise()}}>Назад</div>:null
        }
        {currentComponent}
        {
        this.state.currentPage == 0?  <button disabled={(!this.state.acceptTerms || !(this.isNotEmpty(this.state.name))||!(this.isNotEmpty(this.state.sname))||this.state.currentUser.birthday == null)} onClick={()=>this.changePageState(1)}>Далее</button>:null}
        
        {
          this.state.currentPage == 0? <div className='RegisterPage__Row  TermsOfUse__Input' style={{width:"100%"}}>
          <input style={{width:"5vh"}} onChange={() => this.setState({acceptTerms:!this.state.acceptTerms})} type="checkbox"/>
          <label >Я соглашаюсь, чтобы указанные мной персональные данные были использованы для обработки этого обращения. <a target="_blank" href="/TermsOfUse">Подробнее.</a></label>
         
          </div>:null
        }
          {
        (this.state.currentPage != 0 && this.state.currentPage != 9 && this.state.currentPage != 1 && this.state.currentPage!=10)?
        <div className='Registration__Page__BackArrow' onClick={() => {this.changePageState(1);this.renderChoise()}}>Готово</div>:null
        }
        </div>
      </Container>
    
      </div>
    )
  }
}
