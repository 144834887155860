export class ExpirienceViewModel{
    constructor(){
    this.staffTypeId = 1;
    this.dateFrom = null;
    this.dateTo = null;
    this.company= null;
    this.place = null;
    this.position = null;
    this.recom = 1;
    this.recomContact = null;
    this.leaveReason = null;
    }
}

