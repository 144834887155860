import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Guest from '../Source/Images/Guest.jpg'

export default class RegisterFinal extends Component {
    constructor(props){
        super(props)
        this.state={
            currentPhoto:null
        }
    }
  render() {
    return (
        <Container className='RegisterPage__Container'>
        <div className='RegisterPage'>
        <h2>Загрузите фото</h2>
        <div className='RegisterPage__AnketaResult'>
        <img src={this.state.currentPhoto==null?Guest:this.state.currentPhoto}/>
        <input type='file' acaccept="image/*" onChange={e => {this.props.setPhoto(e.target.files[0]);this.setState({currentPhoto:URL.createObjectURL(e.target.files[0])})}}/>
        </div>
        <button onClick={()=>this.props.Submit()}>Отправить на проверку</button>
        </div>
        {this.props.result}
        </Container>
    )
  }
}


