import React, { Component } from 'react'
import { ExpirienceViewModel } from '../ViewModels/ExpirienceViewModel';
import '../Source/Styles/Expirience.css'
export default class 
 ExpirienctPage extends Component {
    constructor(props){
        super(props)

        this.state ={
            key:0,
            currentExpirience:new ExpirienceViewModel(),
            StaffExperience: props.staffExperiences,          
            form:  null,
            error:"",
            filters:props.filters

        } 
    }

    setExperience(){
      this.props.setExperiences(this.state.StaffExperience)
      this.props.changePageState(1)
      }
    
    cancelInput(){
      this.setState({currentExpirience:new ExpirienceViewModel()});
      this.setState({form:null})
    }

    renderForm(index){
        return(<div key={ index + "form"}  className='ExpiriencePage__Form' >
        <button onClick={()=>this.cancelInput()} style={{marginTop:'3vh',backgroundColor:"#E85A4F"}} >Отменить</button>
        <label>Должность</label>
        <select onChange={e => this.state.currentExpirience.staffTypeId=e.target[e.target.selectedIndex].getAttribute("data-id")}>
          {
           this.state.filters.staffType.map(val=><option  key={val.name+val.id} data-id={val.id} >{val.name}</option> )
          }
        </select>
        <label>Описание </label>
        <textarea onChange={(e)=>this.state.currentExpirience.position = e.target.value} placeholder='(для нянь, гувернанток, сиделок - пол и возраст подопечного, для домработниц, помощников по хозяйству и семейных пар - площадь дома/квартиры и т.п., для водителя - семейный или персональный водитель, марка автомобиля)'/>

        <label>Компания</label>
        <input onChange={(e)=>this.state.currentExpirience.company = e.target.value} type="text" />
        <label>Обязанности</label>
        <input type="text"/>
        <label className='Register__Required'>Дата начала</label>
        <input onChange={(e)=>this.state.currentExpirience.dateFrom = e.target.value} type="date"/>
        <label className='Register__Required'>Дата окончания</label>
        <input onChange={(e)=>this.state.currentExpirience.dateTo = e.target.value}type="date"/>
        <label>Рекомендации</label>
        <select onChange={(e)=>this.state.currentExpirience.recom = e.target[e.target.selectedIndex].getAttribute("data-id")} >
          <option data-id={0} >Нет</option>
          <option data-id={1}>Нужно уточнить</option>
          <option data-id={2}>Письменно</option>
          <option data-id={3}>По телефону</option>
          <option data-id={4}>По требованию</option>

          </select>       
          <label>Имя, номер телефона рекомендателя</label>
        <input onChange={e=>this.state.currentExpirience.recomContact=e.target.value}  type="text"/>
        <label className='Register__Required'>Причина ухода</label>
        <input type="text" placeholder='Причина ухода'/>
        <button onClick={()=>this.addExpirience()} style={{marginTop:"2vh"}} >Добавить</button>
        
        </div>)
    }
    addExpirience(){
      var currentExp = this.state.currentExpirience;
      var canAdd = currentExp.dateFrom != null && currentExp.dateTo != null && currentExp.staffTypeId != null
      if(!canAdd){
        this.setState({error:"Не все обязательные поля заполнены"})
        return
      }
        this.setState({ 
            StaffExperience: this.state.StaffExperience.concat([this.state.currentExpirience])
          })
        this.setState({currentExpirience:new ExpirienceViewModel()})
        this.setState({key:++this.state.key})
        this.setState({inputVisible:false})  
        this.setState({form:null})
        this.setState({error:""})
      }
    addNew(){
      this.setState({currentExpirience:new ExpirienceViewModel()})
      this.setState({form:this.renderForm(this.state.key)})
    }
    removeExpirience(index){
        this.setState({StaffExpirience:this.state.StaffExperience.splice(index,1)})
    }
  render() {
    return (
      <div style={{textAlign:"center"}}>
         <h2>Опыт работы в семьях</h2>
         <div className='Error'>
  {this.state.error}
  </div>
      <div className='Expirience__Page__Full'>
       

  <div className='ExpiriencePage__Container'>
    
    <div  className='ExpiriencePage'> 
        {this.state.form}
        
    </div>   
  
  </div>
  <div style={{visibility:this.state.form==null?"visible":"hidden"}}  className='ExpiriencePage__Result'>
            <button style={{width:"30vw"}} onClick={()=>this.setExperience()} >Готово</button>
            {
                this.state.StaffExperience.map((val,index)=><div className='ExpiriencePage__Result__Row'>
                    <div className='ExpiriencePage__Result__Column'>
                    <div key={val.staffTypeId + "_result__StaffType"}>
                    {
                    this.state.filters.staffType.find(value => value.id == val.staffTypeId).name
                    }
                    </div>
                    <div>
                    {val.dateFrom.split("-")[1]}.{val.dateFrom.split("-")[0]} - {val.dateTo.split("-")[1]}.{val.dateTo.split("-")[0]}
                    </div>
                    </div>
                    <div className='ExpiriencePage__Result__Row__Remove' onClick={()=>this.removeExpirience(index)}>
                    ×
                    </div>
                    <div>

                    </div>
                </div>)
            }
            <button style={{marginTop:"10vh"}} onClick={()=>this.addNew()} >Добавить</button>
        </div>
 
  </div>
  </div>
    )
  }
}
