import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../Source/Styles/NotFound.css'
export default class NotFound extends Component {
  render() {
    return (
      <div className='NotFound__Page'>
        <h1>Страница не найдена</h1>
        <Link to='/'><h2>На главную</h2></Link>
      </div>
    )
  }
}
