import authService from '../components/api-authorization/AuthorizeService'
import axios from "axios";


export const SOURCE_URL = 'static'

 class Api{
	
	async get(path) 
	{
	var result = await fetch("api/"+path);
	return await result.json()
	}
	async post(path,data){
		var result = await fetch('api/'+path,{
			method: "POST",
			headers: {
				'Content-Type':'application/json',
				'Accept': 'application/json',
				"Authorization": "Bearer "+ await authService.getAccessToken() 
			  },
			body:JSON.stringify(data)
		}
		)
		return await result.json()
	}
	async submitForm(path,data){
		var result = await fetch("api/"+path,{
			headers: { 
			
			"Authorization": "Bearer "+ await authService.getAccessToken() ,},
			body: data,
			method: "POST"
		}

		)

		return (await result).json()
	}
}

export  const api =  new Api();


