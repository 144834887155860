export class AddUserViewModel{

    constructor(){
        this.sname = ""
        this.name = ""
        this.mName = ""
        this.email = null
        this.skype = null
        this.height = null
        this.width = null
        this.clothSize = null
        this.distMetro =null
        this.educatuinCurses = null
        this.moreVisa = null
        this.moreLang = null
        this.moreTravel = null
        this.moreAllergy = null
        this.technique = null
        this.skills = null
        this.hobbies = null
        this.personality = null
        this.salaryPerMoth = null
        this.salaryPerDay = null
        this.salaryPerHour = null
        this.commissionPercent = 50
        this.moreConfession = null
        this.commentFromOldDb = null
        this.childAgeFrom = null
        this.childAgeTo = null
        this.isSmoker = false
        this.hasCovidDocumet = false
        this.fearOfDogs = false
        this.workedFromUs = false
        this.hasPatent = false
        this.hasMedknigka = false
        this.hasSudimost = false
        this.hasBiopassport = false
        this.passporInOffice = false
        this.hasRvp = false
        this.staffTypeId = 1
        this.directionId = null
        this.cityId = 1 
        this.citizenId = 10
        this.nationalityid = 1
        this.metroStationId = null
        this.Staff2DutyTypes = []
        this.Staff2ScheduleTypes=[]
        this.birthday = null
        this.acceptedForModeration = false
        this.fcs = ""
        this.idNavigation = {ContactTypeId:1,ContactType:{id:1,name:" "}}
        this.genderId = 2;
        this.PhotoPath = null
    }
    finalize(){
        this.name = this.fcs.split(" ")[1]
        this.sname = this.fcs.split(" ")[0]
        this.mName= this.fcs.split(" ")[2]
    }
}