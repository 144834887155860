import React, { Component } from 'react';
import {api} from '../../http/http';
import '../../Source/Styles/Home.css'
import { FilterViwewModel } from '../../ViewModels/FiltersViewModel';
import Guest from '../../Source/Images/Guest.jpg'
import { SOURCE_URL } from '../../http/http';
export class Home extends Component {
  static displayName = Home.name;
  constructor(props) {
    super()
    this.state={ 
      Staff:NaN,
      ViewModel:new FilterViwewModel(),
      FilterResult:null,
      loading:true}
    
  }
  async componentDidMount() {
    this.setState({ViewModel:new FilterViwewModel()})
    var filters = await fetch("api/Filters")
    this.setState({Staff : await filters.json()})
    this.setState({loading : false});
  }
  
  async searchByFilter(){
    
    this.setState({FilterResult :await api.post("FilteredSearch",this.state.ViewModel.finalize())})
    this.setState({loading : false});
    console.log(this.state)
  }

  changeViewModelArrayValue(obj,value){
    let currentIndex = obj.indexOf(value);
    if (currentIndex !== -1) {
      obj.splice(currentIndex, 1);
    }
    else{
      obj.push(value)
    }  
  }
  changeViewModelPropValue(obj,value){
    obj.obj = value
    console.log(value)
  }
 
  setIfNotNull(value){
    return value == null?"Не указано":value
  }
  setPhotoIfNotNull(path){
    //todo
    //if(path != null){
     // return SOURCE_URL+ "/" + path
    //}
    return Guest
  }

  renedrResult(result) {
    return(
      
      <div className="HomePage">
        <div className='Filter__Row Filter__Page' >
       
         <div className='Filter__Column'style={{backgroundColor:"#DEF7FE",height:"85vh"}}>
           <h2 className='Filter__Title'>Обязанности</h2>
           <div style={{display:"flex",flexDirection:"column",width:"45vw",flexWrap:"wrap",height:"83vh"}}>
            
           {
            result.duty.map(dut => <div key={"dut-"+dut.id} className='Filter__Row' style={{width:"fit-content"}}> 
            <input onChange={()=>this.changeViewModelArrayValue(this.state.ViewModel.dutyIds,dut.id)} id={"dut_"+dut.id} value={dut.id} type="checkbox"/>
            <label htmlFor={"dut_"+dut.id}>{dut.name}</label>
            </div>)
          }
          <div className='Filter__Row' style={{backgroundColor:"#C3FBD8"}}>
          <div className='Filter__Column'>
           <h2 className='Filter__Title'>График</h2>
           <div>
           {
            result.scheduleType.map(st => <div key={"st-"+st.id} className='Filter__Row' > 
            <input onChange={()=>this.changeViewModelArrayValue(this.state.ViewModel.scheduleTypeIds,st.id)} id={"st_"+st.id} value={st.id} type="checkbox"/>
            <label htmlFor={"st_"+st.id}>{st.name}</label>
            </div>)
          }
           </div>
          </div>
           
         </div>
          </div>
          
         </div>
         <div className='Filter__Column' style={{backgroundColor:"#FED6BC"}}>
          <h2 className='Filter__Title'>Гражданство</h2>
          {
            result.citizenship.map(cit => <div key={"cit-"+cit.id} className='Filter__Row' > 
            <input onChange={()=>this.changeViewModelArrayValue(this.state.ViewModel.citizenshipIds,cit.id)} id={"cit_"+cit.id} value={cit.id} type="checkbox"/>
            <label htmlFor={"cit_"+cit.id}>{cit.name}</label>
            </div>)
          }
          <div className='Filter__Column' >
          <label htmlFor='nationality_list'>Национальность</label>
          <select  onChange={(e)=>this.state.ViewModel.nationalityId=e.target[e.target.selectedIndex].getAttribute("data-id")} id="nationality_list" >
          <option data-id={null}>Не фильтровать</option>
          {
            result.nationality.map(val=><option  key={"nat_"+val.id} data-id={val.id} >{val.name}</option> )
          }
          </select>        
          </div>
         </div>
        
         <div className='Filter__Column'>
          <div className='Filter__Column'>
          <label htmlFor='staff_type'>Должность</label>
          <select onChange={(e)=>this.state.ViewModel.staffTypeId=e.target[e.target.selectedIndex].getAttribute("data-id")} id="staff_list" >
          <option data-id={null}>Не фильтровать</option>
          {
            result.staffType.map(val=><option  key={"stf_"+val.id} data-id={val.id} >{val.name}</option> )
          }
          </select>
          </div>
          <div className='Filter__Column'>
          <label htmlFor='direction'>Направление</label>
          <select onChange={(e)=>this.state.ViewModel.directionId=e.target[e.target.selectedIndex].getAttribute("data-id")} id="directions_list" >
          <option data-id={null}>Не фильтровать</option>
          {
            result.direction.map(val=><option  key={"dir_"+val.id} data-id={val.id} >{val.name}</option> )
          }
          </select>
          </div>
          <label htmlFor='city_list'>Город</label>
          <select style={{width:"100%"}} onChange={(e)=>this.state.ViewModel.cityId=e.target[e.target.selectedIndex].getAttribute("data-id")} id="city_list" >
          <option data-id={null}>Не фильтровать</option>
          {
            result.city.map(val=><option  key={"ct_"+val.id} data-id={val.id} >{val.name}</option> )
          }
          </select>
          <div className='Filter__Column'>
          <label htmlFor='metro'>Метро</label>
          <select onChange={(e)=>this.state.ViewModel.metroId=e.target[e.target.selectedIndex].getAttribute("data-id")} id="metro_list" >
          <option data-id={null}>Не фильтровать</option>
          {
            result.metro.map(val=><option  key={"mt_"+val.id} data-id={val.id} >{val.name}</option> )
          }
          </select>       
          </div>
          
          <div className='Filter__Column'>
            <label htmlFor='salary'>Зарплата (тыс. руб./мес.)</label>
            <input onChange={(e)=>this.state.ViewModel.salary=e.target.value} id="salary" min="0" type="number"/>
          </div >
          <div className='Filter__Column'>
            <label htmlFor='child_age'>Возраст ребёнка <br/> (только для нянь)</label>
            <input onChange={(e)=>this.state.ViewModel.childAge=e.target.value}  id="child_age" min="0" type="number"/>
          </div >
          
          <div className='Filter__Column' style={{backgroundColor:"#DCDCDC"}}>
          <h6>Возраст соискателя</h6>
            <label htmlFor='age_from'>От</label>
            <input onChange={(e)=>this.state.ViewModel.staffAgeFrom=e.target.value} id="age_from" min="0" type="number"/>
            <label  htmlFor='age_to'>До</label>
            <input onChange={(e)=>this.state.ViewModel.staffAgeTo=e.target.value} id="age_to" min="0" type="number"/>
          </div >
          <div className='Filter__Column'>
          <label htmlFor='FCS'>ФИО</label>
          <input onChange={(e)=>this.state.ViewModel.fcs=e.target.value} id="FCS" type="text"/>
          </div>
          <div className='Filter__Row'>
          
          <input onChange={()=>this.state.ViewModel.registeredOnline=this.state.ViewModel.registeredOnline==null?true:null} id="reg_online" type="checkbox"/>
          <label htmlFor='reg_online'>Только зар-е онлайн</label>
          </div>
          <div className='Filter__Column'>
            <label htmlFor='rows_count'>Количество записей</label>
          <input onChange={(e)=>this.state.ViewModel.takeTop = e.target.value} id="rows_count" type="number" defaultValue={20}  min="1" max="100"/>
          </div>
          
          <button onClick={()=>this.searchByFilter()}>Поиск</button>

         </div>
      </div>
      </div>
      
    )
  

  }
  filterResult(){
    return(
      <div className='Filter__Result'>
        <h2>Результаты поиска:</h2>
        {this.state.FilterResult.map(r => 
        <div className='Result__Column' style={{height:"90vh",border:"solid black 1px"}} >
          <a href={'https://e-nanny.ru/resume/nyanya/'+r.id} target='_blank'>
          <div className='Result__Row'>
          <h5>
          {this.setIfNotNull(r.name)}
            </h5>
          </div>
          <div className='Result__Row'>
            
            <img className='Result__Staff_Photo' src={this.setPhotoIfNotNull(r.photo)}/>
           
          </div>
          </a>
          
          <div className='Result__Row'>
          
          Гражданство:
          
          {this.setIfNotNull(r.citizenship)}
          </div>
          <div className='Result__Row'>
            
          Возраст:
          {this.setIfNotNull(r.age)} 
          </div>
          <div className='Result__Row'>
          Зарплата:{this.setIfNotNull(r.salary)} 
          </div>
          <div className='Result__Row'>
          Опыт:{this.setIfNotNull(r.expirience)} 
          </div>
          <div className='Result__Row'>

          </div>
        </div>)}
      </div>
    )
  }

  render() {
    
    let contents = this.state.loading
    ? <p><em>Loading...</em></p>
    : this.renedrResult(this.state.Staff)
    let searchResult = this.state.FilterResult == null? 
    <div className='Filter__Result'><h2>Результаты поиска:</h2></div> : this.filterResult(this.state.FilterResult)
    return (
      <div className='FilterSearch__Page'>
        {contents}
        <div className='Filter__Result'>
        {searchResult}
        </div>
        
      </div>
    );
  }
}
  

  
