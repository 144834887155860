export class ContactViewModel{
    contactTypeId = 2;
    EmailAddresses = [new Email()]
    Phones = [new Phone()]
}
class Email{
    constructor(){
        this.Email = ""
    }
}
class Phone{
    constructor(){
        this.Number="";
        this.isWhatsapp=false;
        this.isTelegram=false;
    }
}