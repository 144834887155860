export class FilterViwewModel{
    constructor(){
        this.takeTop=20;
        this.citizenshipIds= [];
        this.dutyIds=[];
        this.scheduleTypeIds=[];
        this.nationalityId=null;
        this.staffTypeId=null;
        this.directionId=null;
        this.cityId=null;
        this.metroId=null;
        this.salary=null;
        this.childAge=null;
        this.staffAgeFrom=null;
        this.staffAgeTo=null;
        this.fcs=null; 
        this.registeredOnline=null;
       
     
    }

    //Возвращаем модель, с заменой пустых полей на null
   finalize(){
        var result = new FilterViwewModel();
        for(var key in this){
            result[key] = this[key]
            if((typeof result[key]) =="string"){
                result[key].trim()       
            }
            if (result[key]==[] || result[key]=="" || result[key]==undefined || result[key]==NaN){
                
                result[key]=null
            }
        
        }
        return result
   }
}