import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import {Anketa} from "./components/Pages/Anketa"
import { Home } from "./components/Pages/Home";
import Register from './components/Pages/Register';
import TermsOfUse from './components/Pages/TermsOfUse';

const AppRoutes = [
  {
    path:'/filters',
    element: <Home />,
    requireAuth: true
  },
  {
    path: '/Anketa',
    element: <Anketa />,
    requireAuth: true
  },
  {
    path: '/',
    element:<Register/>,
    requireAuth:false
  },
  {
    path:'/TermsOfUse',
    element:<TermsOfUse/>,
    requireAuth:false
  },

  ...ApiAuthorzationRoutes
];

export default AppRoutes;
